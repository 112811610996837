import { Text, Title } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Checkbox, Container, FormControlLabel, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const workedBreakAcknowledgementSchema = z.object({
  confirmation: z.boolean(),
});
export type WorkedBreakAcknowledgement = z.infer<typeof workedBreakAcknowledgementSchema>;

export interface WorkedBreakPolicyAcknowledgementPageProps {
  onCancel: () => void;
  onSubmit: () => void;
  facilityName: string;
  mandatoryBreakPolicy: string;
}

export function WorkedBreakPolicyAcknowledgementPage(
  props: WorkedBreakPolicyAcknowledgementPageProps
): JSX.Element {
  const { onCancel, onSubmit, facilityName, mandatoryBreakPolicy } = props;

  const formMethods = useForm<WorkedBreakAcknowledgement>({
    resolver: zodResolver(workedBreakAcknowledgementSchema),
  });

  const { watch, register, handleSubmit } = formMethods;
  const isConfirmed = watch("confirmation");

  return (
    <Container maxWidth="xl" sx={{ height: "100%" }}>
      <FormProvider {...formMethods}>
        <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" height="100%" sx={{ textSizeAdjust: "100%" }}>
            <Stack direction="column" paddingTop={2} spacing={2} flexGrow={1}>
              <Title component="h1">Why didn&apos;t you take a complete break?</Title>
              <Text>
                You are attempting to submit a timesheet with an incomplete break.{" "}
                <b>{facilityName} requires that you take a 30-minute unpaid break.</b>
              </Text>
              <Text>
                As a reminder, {facilityName} has the following mandatory break policy which you
                acknowledged when you booked a shift with the workplace:
              </Text>
              <Box sx={{ overflow: "auto" }}>
                {mandatoryBreakPolicy.split("\n").map((mandatoryBreakPolicyLine, index) => (
                  <Text
                    key={`${mandatoryBreakPolicyLine}-${index + 1}`}
                    fontStyle="italic"
                    paddingLeft={2}
                  >
                    {mandatoryBreakPolicyLine}
                  </Text>
                ))}
              </Box>
              <Text>
                If you believe your incomplete break is accurate and abides by the workplace&apos;s
                mandatory break policy above, you may request compensation for worked-through break
                time by tapping &apos;Continue&apos; below.
              </Text>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={2} paddingY={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    role="checkbox"
                    {...register("confirmation")}
                    sx={{ alignSelf: "flex-start" }}
                  />
                }
                label={
                  <Text variant="body2">
                    I acknowledge {facilityName}&apos;s mandatory break policy, and my incomplete
                    break is consistent with this policy
                  </Text>
                }
              />
              <Button
                fullWidth
                name="break-acknowledgement-continue"
                variant="contained"
                type="submit"
                size="small"
                disabled={!isConfirmed}
              >
                Continue
              </Button>
              <Button fullWidth variant="outlined" size="small" onClick={onCancel}>
                Go Back
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
}
